<script>
import simplebar from "simplebar-vue";

import MetisMenu from "metismenujs/dist/metismenujs";

import { menuItems } from "./menu";
import Footer from "@/components/footer";

import {
  SET_TOPBAR_HEIGHT
} from "@/state/actions.type";

export default {
  components: {
    simplebar,
    Footer
  },
  data() {
    return {
      menuItems: menuItems,
      searchText: ""
    };
  },

  computed: {

    roles() {
      return this.$store.state.auth.currentUser.roles;
    },

    isGlobalUser() {
      return this.$store.state.auth.currentUser.is_global_user;
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    IsReconEnabled(){
      return this.$store.state.auth.currentUser.recon_enabled;
    },

    isCrossBorder() {
      return this.$store.state.auth.currentUser.cross_border_enabled;
    },

    isOnboardingCompleted() {
      return this.$store.state.auth.currentUser.is_onboarding_completed;
    },

    isReconAnalyticsEnabled() {
      return this.$store.state.auth.currentUser.recon_analytics_enabled;
    },

    currentRouteName() {
      return this.$route;
    },
  },


  mounted: function () {
    // eslint-disable-next-line no-unused-vars
    MetisMenu.attach('#side-menu');
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    let similarMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      } else if(window.location.pathname.includes(links[i].pathname) && links[i].pathname !== '/') {
        similarMenuItem = links[i];
      }
    }

    if(!matchingMenuItem && similarMenuItem) {
      matchingMenuItem = similarMenuItem;
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== "side-menu") {
              parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== "side-menu") {
                parent5.classList.add("mm-active");
                const childanchor = parent5.querySelector(".is-parent");
                if (childanchor && parent5.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  },

  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    onRoutechange() {
      setTimeout(() => {
        const currentElement = document.getElementsByClassName("mm-active")[0];
        const currentPosition = currentElement ? currentElement.offsetTop : 0;
        if (currentPosition > 400)
          this.$refs.currentMenu.SimpleBar.getScrollElement().scrollTop =
            currentPosition + 200;
      }, 300);
    },
    toggleMenu() {
      this.$parent.toggleMenu();

      setTimeout(() => {
        this.$store.dispatch(`auth/${SET_TOPBAR_HEIGHT}`, {
          topbarHeight: document.getElementById('topbar').clientHeight || 0,
          sandboxModeAlertHeight: document.getElementById('sandboxAlert').clientHeight || 0
        });
      }, 500);
    },
    canAccess(routePermissions, subitem) {
      // if(this.roles && this.roles.includes('admin')) return true;
      // if(subitem && this.roles && this.roles.includes('sub-admin') && subitem.link === '/integration/api-general') {
      //   return false;
      // }
      if(subitem && subitem.link === "/" && this.isGlobalUser) {
        return true;
      }
      // if(subitem && subitem.link === '/dashboard' && this.isGlobalUser) {
      //   return false;
      // }
      if(routePermissions.length == 0 || (this.roles && (this.roles.includes('admin') || this.roles.includes('sub-admin')))) return true;
      if(routePermissions) {
        // console.log({routePermissions, roles: this.roles});
        // let has_some = false;
        // const has_access = routePermissions.every(val => {
        //   has_some = has_some || this.roles.includes(val);
        // });
        let has_access = false;
        this.roles.forEach(val => {
          if(routePermissions.includes(val)) {
            has_access = true;
          }
        })
        return has_access;
      }
      return false;
    },
    masterMerchantAccess(masterMerchantVal) {
      if(masterMerchantVal == "onlyReconAnalyticsIfEnabled" && this.isReconAnalyticsEnabled){
        // this will work when only recon analytics is enabled
        return true;
      }
      if(masterMerchantVal == "onlyReconTransactionIfReconEnabled" && this.IsReconEnabled){
        // this will work when only recon transaction is enabled
        return true;
      }
      
      if(masterMerchantVal == "crossBorder" && this.isCrossBorder){
          // this will work when cross border is enabled 
          return true;
      }
      if(masterMerchantVal == "MasterMerchant" && this.isMasterMerchant){
        // this will work when master merchant is enabled
        return true;
      }
      if(masterMerchantVal == "onlyForcrossBorder" && (this.isCrossBorder && !this.isMasterMerchant)){
         // this will work when only cross border module want to show 
          return true;
      }
     if(masterMerchantVal == "MasMerchantWthMerchant" && !(this.isCrossBorder && !this.isMasterMerchant)){ 
        return true;
      }
      if(masterMerchantVal == "All"){
        return true
      }
      if(masterMerchantVal == "Merchant" && !this.isMasterMerchant && !this.isCrossBorder){
        return true
      }
      return false;
    }

  },

  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
};
</script>
<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <div class="vertical-menu-div">
      <b-btn variant="white" class="toggle-sidebar p-0" @click="toggleMenu">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M7.81 2H16.19C19.83 2 22 4.17 22 7.81V16.18C22 19.83 19.83 22 16.19 22H7.82C4.18 22 2.01 19.83 2.01 16.19V7.81C2 4.17 4.17 2 7.81 2ZM10.21 15C9.92 15.29 9.92 15.77 10.21 16.06C10.36 16.21 10.55 16.28 10.74 16.28C10.93 16.28 11.12 16.21 11.27 16.06L14.8 12.53C14.9395 12.3889 15.0177 12.1984 15.0177 12C15.0177 11.8016 14.9395 11.6111 14.8 11.47L11.27 7.94C10.98 7.65 10.5 7.65 10.21 7.94C9.92 8.23 9.92 8.71 10.21 9L13.21 12L10.21 15Z" fill="black"/>
        </svg>
      </b-btn>
      <div class="sidebar-top">
        <router-link :to="{ name: (roles.includes('admin') || roles.includes('sub-admin') || roles.includes('payment-admin')) ? 'Dashboard' : 'User Profile' }" class="logo">
          <img class="logo-sm" src="@/assets/images/portone-icon.png" alt="PortOne" height="50" width="38" />
          <img class="logo-lg" src="@/assets/images/portone.png" alt="PortOne" height="25" width="110" />
        </router-link>
    
        <!-- <div class="input-group global-search">
          <div class="input-group-prepend my-auto">
            <img src="@/assets/images/search.svg" alt="search" height="24px" />
          </div>
          <b-input class="border-0 my-auto bg-transparent" v-model="searchText" placeholder="Search..."></b-input>
        </div> -->
      </div>
  
      <div>
        <simplebar ref="currentMenu" id="my-element">
          <!--- Sidemenu -->
          <div id="sidebar-menu">
            <!-- Left Menu Start -->
            <ul class="metismenu list-unstyled" id="side-menu" style="height: calc(100vh - 230px); overflow-y: scroll; overflow-x: hidden;">
              <template v-for="item in menuItems">
                <li v-if="item.isTitle && canAccess(item.access) && masterMerchantAccess(item.merchantType)" :key="item.id">
                  <p class="menu-title">{{ $t(item.label) }}</p>
                </li>
    
                <li v-if="!item.isTitle && !item.isLayout" :key="item.id">
    
                  <!-- No Sub Items -->
                  <router-link
                    :to="item.link"
                    v-if="!hasItems(item) && canAccess(item.access, item) && masterMerchantAccess(item.merchantType)"
                    class="side-nav-link-ref"
                    :class="{ 'disable-side-bar': !isOnboardingCompleted && item.disabelBeforeOnboarding }"
                  >
                    <div v-if="item.icon || item.iconActive" class="my-auto" v-b-tooltip.hover.right="{title: $t(item.label), delay: { show: 150, hide: 0 }}">
                      <img v-show="!$route.path.startsWith(item.link) || !item.iconActive" :src="item.icon" :alt="item.icon">
                      <img v-show="$route.path.startsWith(item.link) && item.iconActive" :src="item.iconActive" :alt="item.iconActive">
                    </div>
                    <span class="align-self-center">
                      {{ $t(item.label) }}
                    </span>
                  </router-link>

                  <!-- Has Sub Items -->
                  <a
                    v-if="hasItems(item) && canAccess(item.access) && masterMerchantAccess(item.merchantType)"
                    href="javascript:void(0);"
                    class="is-parent d-flex justify-content-between"
                    :class="{
                      'has-arrow': !item.badge,
                      'has-dropdown': item.badge,
                      'disable-side-bar': !isOnboardingCompleted && item.disabelBeforeOnboarding
                    }"
                  >
                    <div class="d-flex">
                      <div v-if="item.icon || item.iconActive">
                        <img v-show="!$route.path.startsWith(item.link) || !item.iconActive" :src="item.icon" :alt="item.icon">
                        <img v-show="$route.path.startsWith(item.link) && item.iconActive" :src="item.iconActive" :alt="item.iconActive">
                      </div>
                      <span class="menu-sub-title align-self-center">{{ $t(item.label) }}</span>
                    </div>
                    <span
                      :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                      v-if="item.badge"
                      >{{ $t(item.badge.text) }}</span
                    >
                  </a>

                  <!-- Sub Items -->
                  <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
                    <li class="side-nav-link-ref d-flex"><span class="menu-sub-title-collapsed">{{ $t(item.label) }}</span></li>
                    <li v-for="(subitem, index) of item.subItems" :key="index">
                      <router-link
                        :to="subitem.link"
                        v-if="!hasItems(subitem) && canAccess(subitem.access, subitem) && masterMerchantAccess(subitem.merchantType)"
                        class="side-nav-link-ref d-flex"
                        >
                        <div v-if="subitem.icon" class="align-content-center">
                          <img v-show="!$route.path.startsWith(subitem.link) || !subitem.iconActive" :src="subitem.icon" :alt="subitem.icon" style="margin-left: 16px;">
                          <img v-show="$route.path.startsWith(subitem.link) && subitem.iconActive" :src="subitem.iconActive" :alt="subitem.iconActive" style="margin-left: 16px;">
                        </div>
                        <span class="align-self-center">{{ $t(subitem.label) }}</span></router-link
                      >
                      <a
                        v-if="hasItems(subitem) && canAccess(subitem.access) && masterMerchantAccess(subitem.merchantType)"
                        class="side-nav-link-a-ref has-arrow"
                        href="javascript:void(0);"
                        >{{ subitem.label }}</a
                      >
                      <ul
                        v-if="hasItems(subitem) && canAccess(subitem.access) && masterMerchantAccess(subitem.merchantType)"
                        class="sub-menu mm-collapse"
                        aria-expanded="false"
                      >
                        <li
                          v-for="(subSubitem, index) of subitem.subItems"
                          :key="index"
                        >
                          <router-link
                            v-if=" canAccess(subSubitem.access) && masterMerchantAccess(subitem.merchantType)"
                            :to="subSubitem.link"
                            class="side-nav-link-ref"
                            >{{ $t(subSubitem.label) }}</router-link
                          >
                        </li>
                      </ul>
                    </li>
                  </ul>
    
                </li>
              </template>
    
            </ul>
          </div>
          <!-- Sidebar -->
        </simplebar>
  
        <Footer ref="footer" />
      </div>
  
    </div>
  </div>
  <!-- Left Sidebar End -->
</template>
<style lang="scss" scoped>
::v-deep .arrow {
  display: none !important;
}
::v-deep .tooltip-inner {
  background-color: #FFF;
  color: #000;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
  border-radius: 12px;
  height: 44px;
  font-size: 14px;
  font-weight: 350;
  line-height: 20px;
  padding: 12px 16px;
  opacity: 1;
}
.tooltip {
  opacity: 1 !important;
  left: 118px !important;
}

.sidebar-enable .tooltip {
  display: none !important;
}
</style>