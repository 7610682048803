<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";
import Loader from "@/components/loader";

export default {
  components: { Vertical, Loader },
  props: {
    loading: Boolean
  },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed
  },
  methods: {}
};
</script>

<template>
  <div>
    <Loader v-if="loading"></Loader>
    <vertical>
      <slot />
    </vertical>
  </div>
</template>
